import React from 'react';
import { IconButton, Tooltip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import {GRID_TREE_DATA_GROUPING_FIELD} from '@mui/x-data-grid-pro';

export const centresColumns = (handleCompanySelect) => [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'name',
    headerName: 'Středisko',
    minWidth: 385,
    flex: 2,
    renderCell: (params) => (
      <div style= {{cursor: 'pointer'}} onClick={() => {
        handleCompanySelect(params.row.id, params.row.name);
      }}
      >{params.value}</div>
    )
  },
  //FIXME: remove after accepted
  /*
  {
    field: 'actions',
    headerName: '',
    width: 130,
    sortable: false,
    disableClickEventBubbling: true,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
    renderCell: (params) => (
      <div style={{display: 'inline-block', justifyContent: 'end', alignItems: 'center', width: '100%'}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            handleCompanySelect(params.row.id, params.row.name);
          }}
        >
          <Typography sx={{fontSize: '9px', margin: "0px", padding: "0px"}}>
            Zobrazit
          </Typography>
        </Button>
      </div>
    ),
  },

   */
];

export const companiesColumns = (handleCompanySelect) => [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'name',
    headerName: 'Firma',
    minWidth: 385,
    flex: 2,
    renderCell: (params) => (
      <div style= {{cursor: 'pointer'}} onClick={() => {
        handleCompanySelect(params.row.id, params.row.name);
      }}
      >{params.value}</div>
    )
  },
  //FIXME: remove after accepted
  /*

  {
    field: 'actions',
    headerName: '',
    width: 130,
    sortable: false,
    disableClickEventBubbling: true,
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
    renderCell: (params) => (
      <div style={{display: 'inline-block', justifyContent: 'end', alignItems: 'center', width: '100%'}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            handleCompanySelect(params.row.id, params.row.name);
          }}
        >
          <Typography sx={{fontSize: '9px', margin: "0px", padding: "0px"}}>
            Zobrazit
          </Typography>
        </Button>
      </div>
    ),
  },

   */
];

export const phasesColumns = (handleEditClick) => [
  {
    field: 'edit',
    headerName: '',
    width: 50,
    sortable: false,
    disableClickEventBubbling: true,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params) => (
      (params.row.isEditable || params.row.costAssumption === undefined) &&
      <IconButton
        sx={{color: "inherit"}}
        onClick={() =>
          handleEditClick(params.row.contractNumber === "" ? params.row : params.row.contractNumber)
        }
      >
        <EditIcon/>
      </IconButton>
    ),
  },
  {
    field: GRID_TREE_DATA_GROUPING_FIELD,
  },
  {
    field: 'contractName',
    headerName: 'Název zakázky',
    minWidth: 150,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'assumptionValue',
    headerName: 'Plán',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => (
      Math.abs(params.row.costAssumption - params.row.sumCostAssumption) < 1 ? formatAmount(params.row.assumptionValue) :
        <Tooltip
          title={params.row.contractNumber === "" ? " Jedna ze zakázek v tomto měsíci obsahuje chybný plán" : "Plán pro tuto zakázku je: " + params.row.costAssumption + ". Kdežto suma plánů pro jednotlivé měsíce je: "
            + params.row.sumCostAssumption} PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: params.row.contractNumber === "" ? 'orange' : 'red',
              color: 'white',
              fontSize: 10,
              padding: '8px 12px',
            }
          }
        }}>
          <div style={{
            alignItems: 'center',
            display: 'inline-flex',
            color: params.row.contractNumber === "" ? 'black' : 'red'
          }}>
            {params.row.contractNumber && <ErrorIcon sx={{color: 'red', fontSize: 18, marginRight: 0.5}}/>}
            {!params.row.contractNumber && <WarningIcon sx={{color: 'orange', fontSize: 18, marginRight: 0.5}}/>}
            {formatAmount(params.row.assumptionValue)}
          </div>
        </Tooltip>
    )
  },
  {
    field: 'scopeValue',
    headerName: 'Smlouva',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    renderCell: (params) => (
      Math.abs(params.row.scope - params.row.sumScope) < 1 ? formatAmount(params.row.scopeValue) :
        <Tooltip
          title={params.row.contractNumber === "" ? " Jedna ze zakázek v tomto měsíci obsahuje chybný plán" : "Plán pro tuto zakázku je: " + params.row.costAssumption + ". Kdežto suma plánů pro jednotlivé měsíce je: "
            + params.row.sumCostAssumption} PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: params.row.contractNumber === "" ? 'orange' : 'red',
              color: 'white',
              fontSize: 10,
              padding: '8px 12px',
            }
          }
        }}>
          <div style={{
            alignItems: 'center',
            display: 'inline-flex',
            color: params.row.contractNumber === "" ? 'black' : 'red'
          }}>
            {params.row.contractNumber && <ErrorIcon sx={{color: 'red', fontSize: 18, marginRight: 0.5}}/>}
            {!params.row.contractNumber && <WarningIcon sx={{color: 'orange', fontSize: 18, marginRight: 0.5}}/>}
            {formatAmount(params.row.scopeValue)}
          </div>
        </Tooltip>
    )
  },
  {
    field: 'difference',
    headerName: 'Rozdíl',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'company',
    headerName: 'Dodavatel',
    minWidth: 150,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'description',
    headerName: 'Popis',
    minWidth: 150,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'amountBase',
    headerName: 'Základ',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'amountVat',
    headerName: 'DPH',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'amountTotal',
    headerName: 'Celkem',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'actualPayment',
    headerName: 'Skutečná uhrada',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'expoDate',
    headerName: 'Vystaveno',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return removeTimezone(value)
    }
  },
  {
    field: 'dueDate',
    headerName: 'Splatnost',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return removeTimezone(value)
    }
  },
  {
    field: 'paymentDate',
    headerName: 'Zaplaceno',
    minWidth: 150,
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueFormatter: (value) => {
      return removeTimezone(value)
    }
  },
  {
    field: 'statusPaid',
    headerName: 'Uhrazenost',
    minWidth: 150,
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
];

export const contractsColumns = () => [
  {
    field: 'code',
    headerName: 'Kód',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Název',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'contractNumber',
    headerName: 'Číslo smlouvy',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Platí od',
    minWidth: 150,
    flex: 1,
    valueFormatter: (value) => {
      return removeTimezone(value)
    }
  },
  {
    field: 'endDate',
    headerName: 'Platí do',
    minWidth: 150,
    flex: 1,
    valueFormatter: (value) => {
      return removeTimezone(value)
    }
  },
  {
    field: 'costAssumption',
    headerName: 'Plán',
    headerAlign: 'right',
    align: 'right',
    minWidth: 150,
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'scope',
    headerName: 'Smlouva',
    minWidth: 150,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'newCostAssumption',
    headerName: 'Nový plán',
    minWidth: 150,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'newScope',
    headerName: 'Nová smlouva',
    minWidth: 150,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'realized',
    headerName: 'Realizováno',
    minWidth: 150,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'remaining',
    headerName: 'Zbývá dočerpat',
    minWidth: 150,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },

];

export const groupsAndActivitiesColumns = (months) => [
  ...months.flatMap(month => ([
    {
      field: `assumptions_${month}`,
      headerName: `Plán`,
      align: 'right',
      headerAlign: 'center',
      width: 120,
      valueFormatter: (value) => {
        return formatAmount(value)
      }
    },
    {
      field: `reality_${month}`,
      headerClassName: `spendings`,
      headerName: `Skutečnost`,
      align: 'right',
      headerAlign: 'center',
      width: 120,
      valueFormatter: (value) => {
        return formatAmount(value)
      }
    },
  ])),
  {
    field: 'sum_assumptions',
    headerName: 'Plán',
    align: 'right',
    headerAlign: 'center',
    width: 120,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'sum_reality',
    headerClassName: `spendings`,
    align: 'right',
    headerAlign: 'center',
    headerName: 'Skutečnost',
    width: 120,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'total_assumption_values',
    headerName: 'Plán',
    align: 'right',
    headerAlign: 'center',
    width: 120,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
  {
    field: 'total_values',
    headerName: 'Skutečnost',
    align: 'right',
    headerAlign: 'center',
    width: 120,
    valueFormatter: (value) => {
      return formatAmount(value)
    }
  },
];

export const bankColumns = (months) => [
  {field: "label", headerName: "Tok peněz", width: 180},
  ...months.flatMap((month, index) => [
    {
      field: month,
      headerName: month.slice(0, 2) + "/" + month.slice(2),
      headerAlign: "center",
      width: 240,
      align: "right",
      headerClassName: `month`,
      valueGetter: (value, row) => row?.spendings[index] ?? 0, // Updated
      valueFormatter: (value) => {
        return formatAmount(value)
      },
    },
  ]),
  {
    field: `sum`,
    headerName: "Suma za období",
    headerAlign: "center",
    width: 240,
    align: "right",
    headerClassName: `month`,
    valueGetter: (value, row) => row?.sum ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `total`,
    headerName: "Suma celkem",
    headerAlign: "center",
    width: 240,
    align: "right",
    valueGetter: (value, row) => row?.total ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
];

export const cashflowColumns = (months, handleLabelClick) => [
  {
    field: "label", headerName: "Fáze projektu", width: 180, renderCell: (params) => (
      <div onClick={() => {
        if (handleLabelClick && params.value !== "Celkem") handleLabelClick(params.value)
      }} style={{cursor: handleLabelClick && params.value !== "Celkem" ? "pointer" : "default"}}>
        {params.value}
      </div>
    )
  },
  ...months.flatMap((month, index) => [
    {
      field: `assumptions_${month}`,
      headerName: "Plán",
      headerAlign: "right",
      width: 120,
      align: "right",
      valueGetter: (value, row) => row?.assumptions[index] ?? 0, // Updated
      valueFormatter: (value) => {
        return formatAmount(value)
      },
    },
    {
      field: `spendings_${month}`,
      headerName: "Skutečnost",
      headerAlign: "right",
      width: 120,
      align: "right",
      headerClassName: `spendings`,
      valueGetter: (value, row) => row?.spendings[index] ?? 0, // Updated
      valueFormatter: (value) => {
        return formatAmount(value)
      },
    },
  ]),
  {
    field: `sumAssumption`,
    headerName: "Plán",
    headerAlign: "right",
    width: 120,
    align: "right",
    valueGetter: (value, row) => row?.sumAssumption ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `sum`,
    headerName: "Skutečnost",
    headerAlign: "right",
    headerClassName: `spendings`,
    width: 120,
    align: "right",
    valueGetter: (value, row) => row?.sum ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `totalAssumption`,
    headerName: "Plán",
    headerAlign: "right",
    width: 120,
    align: "right",
    valueGetter: (value, row) => row?.totalAssumption ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
  {
    field: `total`,
    headerName: "Skutečnost",
    headerAlign: "right",
    width: 120,
    align: "right",
    valueGetter: (value, row) => row?.total ?? 0, // Updated
    valueFormatter: (value) => {
      return formatAmount(value)
    },
  },
];


export const groupsAndActivitiesGroupingModel = (months) => [
  ...months.map((month) => ({
    groupId: month,
    headerName: month.slice(0, 2) + "/" + month.slice(2),
    headerAlign: "center",
    headerClassName: `spendings`,
    children: [
      {field: `reality_${month}`, headerName: "Spendings"},
      {field: `assumptions_${month}`, headerName: "Assumptions"},
    ],
  })),
  {
    groupId: 'sum',
    headerName: 'Suma za období',
    headerClassName: `spendings`,
    headerAlign: 'center',
    children: [
      {field: 'sum_reality', headerName: 'sum'},
      {field: 'sum_assumptions', headerName: 'Total Assumption'},
    ],
  },
  {
    groupId: 'total',
    headerName: 'Suma celkem',
    headerAlign: 'center',
    children: [
      {field: 'total_values', headerName: 'sum'},
      {field: 'total_assumption_values', headerName: 'Total Assumption'},
    ],
  },
];


function formatAmount(number) {
  if (number === null || number === '' || isNaN(number)) {
    return '';
  }
  let roundedNumber = Math.round(number); // Round to the nearest whole number
  return roundedNumber.toLocaleString('cs-CZ'); // Format using locale without decimal places
}

function removeTimezone(dateString) {
  if(!dateString) {
    return dateString
  }
  const plusIndex = dateString.indexOf('+');
  if (plusIndex !== -1) {
    return dateString.slice(0, plusIndex);
  }
  return dateString;
}