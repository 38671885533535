import { createTheme } from '@mui/material/styles';

// Create a base theme to get default values
const baseTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      fontSize: '0.75rem',
    },
    h4: {
      fontSize: baseTheme.typography.h4.fontSize,
    },
    h5: {
      fontSize: '1rem',
    },
    h7: {
      fontSize: '0.85rem',
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.85rem',
        },
        secondary: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        rowHeight: 35, // Set the default row height
        columnHeaderHeight: 40, // Set the default column header height
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '35px', // Set the height for all rows
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 8px', // Adjust padding to maintain the cell's structure
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            height: '40px', // Set the height for the header row
          },
        },
      },
    },
  },
});

export default theme;