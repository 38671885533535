import React from "react";
import {Paper} from "@mui/material";
import {
  DataGridPro,
  gridClasses,
} from "@mui/x-data-grid-pro";
import {csCZ} from "@mui/x-data-grid/locales";
import Typography from "@mui/material/Typography";
import {cashflowColumns} from "../data/DatatableColumns";

const columnGroupingModel = (months) => [
  ...months.map((month) => ({
    groupId: month,
    headerName: month.slice(0, 2) + "/" + month.slice(2),
    headerAlign: "center",
    headerClassName: `spendings`,
    children: [
      {field: `spendings_${month}`, headerName: "Spendings"},
      {field: `assumptions_${month}`, headerName: "Assumptions"},
    ],
  })),
  {
    groupId: 'sum',
    headerName: 'Suma za období',
    headerAlign: 'center',
    headerClassName: `spendings`,
    children: [
      {field: 'sum', headerName: 'sum'},
      {field: 'sumAssumption', headerName: 'Total Assumption'},
    ],
  },
  {
    groupId: 'total',
    headerName: 'Suma celkem',
    headerAlign: 'center',
    children: [
      {field: 'total', headerName: 'sum'},
      {field: 'totalAssumption', headerName: 'Total Assumption'},
    ],
  },
];

const convertDateToMMYY = (dateString) => {
  const date = new Date(dateString.split('+')[0]);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear().toString().slice(-2);

  const formattedMonth = month.toString().padStart(2, '0');
  return `${formattedMonth}${year}`;
};

const generateRows = (data, months, rowDefinitions) => {

  // Check if "Total" is already in rowDefinitions
  const totalRowExists = rowDefinitions.some(row => row.code === "Total");
  if (!totalRowExists) {
    rowDefinitions.push({code: "Total", name: "Celkem"});
  }
  const dphImpactRowExists = rowDefinitions.some(row => row.code === "dphImpact");
  if (!dphImpactRowExists) {
    rowDefinitions.push({code: "dphImpact", name: "Vliv DPH"});
  }
  const cashflowRowExists = rowDefinitions.some(row => row.code === "cashflow");
  if (!cashflowRowExists) {
    rowDefinitions.push({code: "cashflow", name: "Čistý finanční tok"});
  }

  const numMonths = months.length;
  const spendings = rowDefinitions.map(() => Array(numMonths).fill(0));
  const assumptions = rowDefinitions.map(() => Array(numMonths).fill(0));

  // Initialize total and totalAssumption arrays for each rowDefinition
  const total = rowDefinitions.map(() => 0);
  const totalAssumption = rowDefinitions.map(() => 0);

  data.report.receivedInvoiceList.forEach(invoice => {
    invoice.paymentList.forEach(payment => {
      const monthYear = convertDateToMMYY(payment.paymentDate);
      const monthIndex = months.indexOf(monthYear);
      if (monthIndex !== -1) {
        spendings[rowDefinitions.findIndex(row => row.code === "dphImpact")][monthIndex] -= invoice.amountVat;
        spendings[rowDefinitions.findIndex(row => row.code === "cashflow")][monthIndex] -= payment.paymentAmount || 0;
      }
      total[rowDefinitions.findIndex(row => row.code === "dphImpact")] -= invoice.amountVat;
      total[rowDefinitions.findIndex(row => row.code === "cashflow")] -= payment.paymentAmount || 0;
    });
  });

  data.report.contractList.forEach(contract => {
    contract.userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        const monthYear = assumption.businessMonth;
        const monthIndex = months.indexOf(monthYear);
        if (monthIndex !== -1) {
          assumptions[rowDefinitions.findIndex(row => row.code === "cashflow")][monthIndex] -= assumption.value;
        }
        totalAssumption[rowDefinitions.findIndex(row => row.code === "cashflow")] -= assumption.value;
      });
    });
  });

  data.report.claimList.forEach(claim => {
    claim.paymentList.forEach(payment => {
      const monthYear = convertDateToMMYY(payment.paymentDate);
      const monthIndex = months.indexOf(monthYear);
      if (monthIndex !== -1) {
        spendings[rowDefinitions.findIndex(row => row.code === "cashflow")][monthIndex] += payment.paymentAmount || 0;
      }
      total[rowDefinitions.findIndex(row => row.code === "cashflow")] += payment.paymentAmount || 0;
    });
    const assumptionMonthYear = convertDateToMMYY(claim.dueDate);
    const assumptionMonthIndex = months.indexOf(assumptionMonthYear);
    if (assumptionMonthIndex !== -1) {
      assumptions[rowDefinitions.findIndex(row => row.code === "cashflow")][assumptionMonthIndex] += claim.amountTotal || 0;
    }
    totalAssumption[rowDefinitions.findIndex(row => row.code === "cashflow")] += claim.amountTotal || 0;
  });
  // Process claimList
  data.report.claimList.forEach(claim => {
    claim.paymentList.forEach(payment => {
      const monthYear = convertDateToMMYY(payment.paymentDate);
      const monthIndex = months.indexOf(monthYear);
      const rowIndex = rowDefinitions.findIndex(row => claim.phaseList.includes(row.code) || claim.sourceList.includes(row.code));
      const actualPayment = payment.paymentAmount || 0;
      if (rowIndex !== -1) {

        spendings[rowIndex][monthIndex] += actualPayment;

        spendings[rowDefinitions.findIndex(row => row.code === "Total")][monthIndex] += actualPayment;

        spendings[rowDefinitions.findIndex(row => row.code === "dphImpact")][monthIndex] += claim.amountVat;

      }
      // Accumulate the total actual payments and assumptions for this row
      total[rowIndex] += actualPayment;

      total[rowDefinitions.findIndex(row => row.code === "Total")] += actualPayment;

      total[rowDefinitions.findIndex(row => row.code === "dphImpact")] += claim.amountVat;
    });
    const assumptionMonthYear = convertDateToMMYY(claim.dueDate);
    const assumptionMonthIndex = months.indexOf(assumptionMonthYear);
    const amountTotal = claim?.amountTotal || 0;
    const rowIndex = rowDefinitions.findIndex(row => claim.phaseList.includes(row.code) || claim.sourceList.includes(row.code));
    if (rowIndex !== -1 && assumptionMonthIndex !== -1) {
      assumptions[rowIndex][assumptionMonthIndex] += amountTotal;
      assumptions[rowDefinitions.findIndex(row => row.code === "Total")][assumptionMonthIndex] += amountTotal;
    }
    totalAssumption[rowIndex] += amountTotal;
    totalAssumption[rowDefinitions.findIndex(row => row.code === "Total")] += amountTotal;
  });

  // Return rows with total and totalAssumption
  return rowDefinitions.map((rowDef, index) => {
    const sum = spendings[index].reduce((sum, value) => sum + value, 0);
    const sumAssumption = assumptions[index].reduce((sum, value) => sum + value, 0);
    return {
      id: index,
      label: rowDef.name,
      spendings: spendings[index],
      assumptions: assumptions[index],
      sum,
      sumAssumption,
      total: total[index],
      totalAssumption: totalAssumption[index]
    };
  });
};
const CashflowIncomes = ({data, months, rowDefinitions, reference, onRowsChange}) => {
  const rows = generateRows(data, months, rowDefinitions)
  onRowsChange && onRowsChange();

  const pinnedRows = {
    bottom: [rows[rowDefinitions.findIndex(row => row.code === "Total")], rows[rowDefinitions.findIndex(row => row.code === "dphImpact")], rows[rowDefinitions.findIndex(row => row.code === "cashflow")]]
  };

  return (
    data.report && months.length > 0 && rowDefinitions.length > 0 &&
    <Paper>
      <Typography
        sx={{
          paddingLeft: 2,
          paddingTop: 1.7,
          paddingBottom: 1.7,
          fontWeight: 'bold',
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          fontSize: "0.85rem",
          height: '45px',
        }}
      >
        Příjmy dle fáze
      </Typography>
      <DataGridPro
        ref={reference}
        rows={rows}
        hideFooter
        disableRowSelectionOnClick
        columns={cashflowColumns(months)}
        columnGroupingModel={columnGroupingModel(months)}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pinnedColumns: {
            left: ['label'],
            right: ['sumAssumption', 'sum', 'totalAssumption', 'total'],
          },
        }}
        disableSelectionOnClick
        disableColumnReorder
        pinnedRows={pinnedRows}
        getCellClassName={(params) => {
          let classNames = '';

          if (params.value < 0) {
            classNames += 'negative ';
          }

          if (params.rowNode.type === 'pinnedRow') {
            if (params.colDef.headerName.includes('Skutečnost')) {
              classNames += 'pinned-row_spendings ';
            } else {
              classNames += 'pinned-row ';
            }
          }

          if (params.colDef.headerName.includes('Skutečnost')) {
            classNames += 'spendings ';
          }

          return classNames.trim();
        }}
        sx={{
          '&.MuiDataGrid-root': {
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold', overflow: 'visible',
          },
          '& .spendings': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          [`.${gridClasses.cell}.pinned-row`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
          },
          [`.${gridClasses.cell}.pinned-row_spendings`]: {
            fontWeight: 'bold',
            backgroundColor: '#f5f5f5',
            borderRight: '1px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-cell.negative': {
            color: 'red',
          },
        }}
      />
    </Paper>
  );
};

export default CashflowIncomes;